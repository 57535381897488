<template>
  <section class="mt-2" :key="updatedKey">  

          <b-table
          class=""
            bordered
            small
            :fields="fields"
            :items="filteredItems"
            :busy="loading"
            :tbody-tr-attr="rowAttributes"
          >
            <template v-slot:cell(auth_type)="data">
              <div v-if="data.item.auth_method == ''">
                {{ $t("AUTH_METHODS." + data.item.auth_type) }}
              </div>
              <div v-if="data.item.auth_method != ''" class="pl-4">
                {{ $t("AUTH_METHODS." + data.item.auth_method) }}
              </div>
            </template>

            <template v-slot:cell(auth_method)="data">
              <span v-if="data.item.auth_method != ''">{{
                $t("AUTH_METHODS." + data.item.auth_method)
              }}</span>
            </template>

            <template v-slot:cell(login)="data">
              <b-form-checkbox
                v-show="showEdit(data.item)"
                @change="checkEdited(data.item)"
                v-model="data.item.login"
                value="1"
                unchecked-value="0"
              >
              </b-form-checkbox>
            </template>

            <template v-slot:cell(external)="data">
              <b-form-checkbox
                switches
                v-show="showEditExternal(data.item)"
                @change="checkEditedExternal(data.item)"
                v-model="data.item.external"
                value="1"
                unchecked-value="0"
              >
              </b-form-checkbox>
            </template>

            <template v-slot:cell(mode)="data">
              <b-form-select  name="authentications_mode" 
                v-model="data.item.mode"
                :options="providers(data.item.auth_type, data.item.auth_method)"
                v-if="showProviders(data.item)"
              >
                <b-form-select-option value="global" v-if="data.item.global_enabled">{{
                  $t("AUTH_METHODS.MODE.global")
                }}</b-form-select-option>
              </b-form-select>

            </template>
          </b-table>
          <b-button
            variant="primary"
            v-if="items.length != 0"
            @click="updateMethods"
            >{{ $t("SAVE") }}</b-button
          >
        

  </section>
</template>
<script>
export default {
  data() {
    return {
      updatedIndex: 0,
      max_loa: null,
      loading: false,
      items: [],
      custom: [],
      fields: [
        {
          key: "auth_type",
          label: "",
          class: " w-150",
          sortable: false,
        },
        {
          key: "loa_level",
          label: this.$t("LOA.NAME"),
          class: "w-icon",
          sortable: false,
        },
        {
          key: "login",
          label: this.$t("AUTH_METHODS.LOGIN"),
          class: "w-icon",
          sortable: false,
        },
        {
          key: "external",
          label: this.$t("AUTH_METHODS.EXTERNAL"),
          class: "w-icon",
          sortable: false,
        },
        {
          key: "mode",
          label: this.$t("AUTH_METHODS.MODE.TITLE"),
          class: "w-300",
          sortable: false,
        },
      ],
    };
  },
  methods: {
    rowAttributes(item) {
      if (this.isPreviousSameType(item) == false) {
        return {
          style: "border-top: 2px solid #7777;",
        };
      }
    },
    isPreviousSameType(data) {
      let checkIndex = this.items.findIndex((x) => x.id === data.id);
      if (checkIndex != 0) {
        let previousIndex = checkIndex - 1;
        return data.auth_type == this.items[previousIndex].auth_type;
      } else {
        return true;
      }
    },
    isNextSameType(data) {
      let checkIndex = this.items.findIndex((x) => x.id === data.id);
      if (checkIndex != 0) {
        let nextIndex = checkIndex + 1;
        if (this.items[nextIndex] != undefined) {
          return data.auth_type == this.items[nextIndex].auth_type;
        } else {
          return false;
        }
      } else {
        return true;
      }
    },
    getMethods: function() {
      let self = this;
      this.loading = true;
      this.$http
        .get(this.user.hostname + "/administration/authentications/list")
        .then((response) => {
          self.items = response.data.items;
          self.updatedIndex++;
          self.loading = false;
        })
        .catch(() => {
          self.loading = false;
        });
    },
    getProviders: function() {
      let self = this;
      this.loading = true;
      this.$http
        .get(this.user.hostname + "/administration/authentication/providers/standard/list")
        .then((response) => {
          self.custom = response.data;
          self.loading = false;
        })
        .catch(() => {
        });
    },
    updateMethods: function() {
      let self = this;
      if (this != null) {
        for (let ix = 0; ix < this.items.length; ix++) {
          this.items[ix].login = parseInt(this.items[ix].login);
          this.items[ix].external = parseInt(this.items[ix].external);
        }
        this.loading = true;
        this.$http
          .post(
            this.user.hostname + "/administration/authentications/update",
            {
              items: this.items,
            }
          )
          .then((response) => {
            self.loading = false;
            self.methods = response.data;
            self.getMethods();
            self.$noty.info(self.$t("SAVED"));
          })
          .catch(() => {
            self.loading = false;
          });
      }
    },
    canEditExternal(item) {
      if (item.mode == "" && item.provider_enabled == 1) {
        return false;
      }
      return true;
    },
    canEdit(item) {
      if (item.mode == "" && item.provider_enabled == 1) {
        return false;
      }
      return true;
    },
    showEdit(item) {
      if (item.auth_type == "sms") {
        return false;
      }
      if (item.auth_type == "email") {
        return false;
      }
      return true;
    },
    showEditExternal(item) {
      if (item.auth_type == "password") {
        return false;
      }
      if (item.auth_type == "freja-org") {
        return false;
      }
      return true;
    },
    checkEdited(item) {
      return item;
      /*if (item.auth_type == "se-eid") {
        if (item.auth_method != "") {
          let index = this.items.findIndex(
            (x) => x.auth_type == "se-eid" && x.auth_method == ""
          );
          let filtered = this.items.filter(
            (c) =>
              c.auth_type == "se-eid" && c.auth_method != "" && c.login == 1
          );
          if (filtered.length == 0) {
            this.items[index].login = 0;
          } else {
            this.items[index].login = 1;
          }
        }
      }if (item.auth_type == "siths-card") {
        if (item.auth_method != "") {
          let index = this.items.findIndex(
            (x) => x.auth_type == "siths-card" && x.auth_method == ""
          );
          let filtered = this.items.filter(
            (c) =>
              c.auth_type == "siths-card" && c.auth_method != "" && c.login == 1
          );
          if (filtered.length == 0) {
            this.items[index].login = 0;
          } else {
            this.items[index].login = 1;
          }
        }
      }*/
    },
    checkEditedExternal(item) {
      return item;
      /*if (item.auth_type == "se-eid") {
        if (item.auth_method != "") {
          let index = this.items.findIndex(
            (x) => x.auth_type == "se-eid" && x.auth_method == ""
          );
          let filtered = this.items.filter(
            (c) =>
              c.auth_type == "se-eid" && c.auth_method != "" && c.external == 1
          );
          if (filtered.length == 0) {
            this.items[index].external = 0;
          } else {
            this.items[index].external = 1;
          }
        }
      }*/
      /*      
      if (item.auth_type == "siths-card") {
        if (item.auth_method != "") {
          let index = this.items.findIndex(
            (x) => x.auth_type == "siths-card" && x.auth_method == ""
          );
          let filtered = this.items.filter(
            (c) =>
              c.auth_type == "siths-card" && c.auth_method != "" && c.external == 1
          );
          if (filtered.length == 0) {
            this.items[index].external = 0;
          } else {
            this.items[index].external = 1;
          }
        }
      }*/
    },
    showProviders(item)
    {
      return item.auth_type != "password";
    },
    providers(auth_type, auth_method) {
      let providerItems = this.custom.filter(function(item) {
        if(auth_type == "se-eid"){
          if(auth_method == ""){
            if( (item.type == "OpenIdConnect") || (item.type == "Saml2") )
            {
              return item;
            }          
          }else{
            if( (item.type == "Certificate") || (item.type == "OpenIdConnect") || (item.type == "Saml2") )
            {
              return item;
            }          
          }
        }   
        if(auth_type == "freja-org"){
          if( (item.type == "Certificate") || (item.type == "OpenIdConnect") || (item.type == "Saml2") )
          {
            return item;
          }          
        }     
        if( (auth_type == "sms") || (auth_type == "password-sms") ){
          //if( (item.type == "Cellsynt") || (item.type == "OpenIdConnect") || (item.type == "Saml2") || (item.type == "infobip") || (item.type == "blueidea") )
          if( (item.type == "Cellsynt") || (item.type == "infobip") || (item.type == "blueidea") || (item.type == "generic") )
          {
            return item;
          }          
        }        
        if(auth_type == "siths-card"){
          if(auth_method == ""){
            if( (item.type == "OpenIdConnect") || (item.type == "Saml2") )
            {
              return item;
            }              
          }else{
            if(auth_method == "net-id-access"){
              if( (item.type == "NetIdAccess") || (item.type == "OpenIdConnect") || (item.type == "Saml2") )
              {
                return item;
              }  
            }
            if(auth_method == "net-id-enterprise"){
              if( (item.type == "NetIdEnterprise") || (item.type == "OpenIdConnect") || (item.type == "Saml2") )
              {
                return item;
              }          
            }
          }
        }        
      }).map(function (x) { return { text: x.name, value: x.name } });
      //providerItems = [];
      providerItems.unshift({ text: this.$t("NONE_SELECTED"), value: "" });
      return providerItems;
      //return providerItems.unshift({ text: "", value: this.$t("NONE_SELECTED") });
    }
  },
  computed: {
    updatedKey(){
      return "loginmethods_" + this.updatedIndex;
    },
    filteredItems(){
      let self = this;
      let filtered = this.items.filter(function(item) {        
        if(item.auth_method == ""){
          return item;
        }else{
          let parent = self.items.filter(
            (c) =>
              c.auth_type == item.auth_type && c.auth_method == ""
          );
          if(parent[0].mode == "")
          {
            return item;
          }
        }
      });
      return filtered;
    }   
  },
  mounted() {
    this.getMethods();
    this.getProviders();
  },
};
</script>
<style></style>
