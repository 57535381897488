<template>
  <section class="mt-2">  

    <b-form inline class="mt-2">

      <b-form-input trim
        class="ml-2"
        v-model="AddCustomAuthMethodSettingDTO.name"
        :placeholder="$t('NAME')"
        @keydown.enter.prevent
      ></b-form-input>

      <b-form-select 
        class="ml-2"
        v-model="AddCustomAuthMethodSettingDTO.mode"
        :placeholder="$t('SELECT')"
        :options="providers"
      >
      </b-form-select>

      <b-button :disabled="!AddCustomAuthMethodSettingDTO.mode" class="ml-2" @click="addBtn">{{ $t('ADD') }}</b-button>
    </b-form>

    

          <b-table
          class="mt-2"
            bordered
            small
            :fields="fields"
            :items="items"
            :busy="loading"
            :tbody-tr-attr="rowAttributes"
          >

            <template v-slot:cell(remove)="data">
              <b-form-checkbox
                v-model="selected"
                :value="data.item.id"
              >
              </b-form-checkbox>
            </template>

          
            <template v-slot:cell(name)="data">
              <b-form-input trim
                class="ml-2"
                v-model="data.item.name"
                :placeholder="$t('NAME')"
              ></b-form-input>
            </template>


            <template v-slot:cell(auth_method)="data">
              <span v-if="data.item.auth_method != ''">{{
                $t("AUTH_METHODS." + data.item.auth_method)
              }}</span>
            </template>

            
            <template v-slot:cell(loa_level)="data">
              <b-form-select  name="custom_authentications_loa_level" 
                v-model="data.item.loa_level"
                :options="loa_levels"
              >
              </b-form-select>

            </template>

            <template v-slot:cell(identifier)="data">
              <b-form-select   name="custom_authentications_identifier" 
                v-model="data.item.identifier"
                :options="identifiers"
              >
              </b-form-select>

            </template>

            <template v-slot:cell(login)="data">
              <b-form-checkbox :disabled="user.information.authentication_type == 'custom' && user.information.authentication_method == data.item.id + ''"
                v-model="data.item.login"
                value="1"
                unchecked-value="0"
              >
              </b-form-checkbox>
            </template>

            <template v-slot:cell(mode)="data">
              <b-form-select  name="custom_authentications_mode" 
                v-model="data.item.mode"
                :options="providers"
              >
              </b-form-select>
            </template>

          </b-table>

          <div v-if="items.length != 0">
            <b-button
              variant="primary"
              @click="updateBtn"
              >{{ $t("SAVE") }}</b-button
            >
        
            <b-button
              variant="danger"
              :disabled="selected.length == 0"
              @click="removeBtn"
              >{{ $t("REMOVE_SELECTED") }}</b-button
            >
          </div>


  </section>
</template>
<script>
export default {
  data() {
    return {
      selected:[],
      updatedIndex: 0,
      max_loa: null,
      loading: false,
      items: [],
      custom: [],
      AddCustomAuthMethodSettingDTO: {
        name: '',
        loa_level: 1,
        login: 1,
        external: 0,
        admin_org: 0,
        mode: null
      },
      fields: [
        {
          key: "remove",
          label: "",
          class: "w-icon",
          sortable: false,
        },
        {
          key: "id",
          label: "Id",
          class: "w-icon",
          sortable: false,
        },
        {
          key: "name",
          label: "",
          class: " w-150",
          sortable: false,
        },
        {
          key: "loa_level",
          label: this.$t("LOA.NAME"),
          class: "w-150",
          sortable: false,
        },
        {
          key: "identifier",
          label: this.$t("IDENTIFICATION"),
          class: "w-150",
          sortable: false,
        },
        {
          key: "mode",
          label: this.$t("AUTH_METHODS.MODE.TITLE"),
          class: "w-300",
          sortable: false,
        },
        {
          key: "login",
          label: this.$t("STATE"),
          class: "w-icon",
          sortable: false,
        }
      ],
      loa_levels: [
         {
          value: 3,
          text: this.$t('LOA.LEVEL.3')
        },
        {
          value: 2,
          text: this.$t('LOA.LEVEL.2')
        },{
          value: 1,
          text: this.$t('LOA.LEVEL.1')
        },{
          value: 0,
          text: this.$t('PROVIDER')
        }
      ],
      identifiers: [
         {
          value: 0,
          text: this.$t('EMAIL')
        },
        {
          value: 1,
          text: this.$t('UNIQUE_IDENTIFIER')
        }
      ]
    };
  },
  methods: {
    rowAttributes(item) {
      if (this.isPreviousSameType(item) == false) {
        return {
          style: "border-top: 2px solid #7777;",
        };
      }
    },
    isPreviousSameType(data) {
      let checkIndex = this.items.findIndex((x) => x.id === data.id);
      if (checkIndex != 0) {
        let previousIndex = checkIndex - 1;
        return data.auth_type == this.items[previousIndex].auth_type;
      } else {
        return true;
      }
    },
    isNextSameType(data) {
      let checkIndex = this.items.findIndex((x) => x.id === data.id);
      if (checkIndex != 0) {
        let nextIndex = checkIndex + 1;
        if (this.items[nextIndex] != undefined) {
          return data.auth_type == this.items[nextIndex].auth_type;
        } else {
          return false;
        }
      } else {
        return true;
      }
    },
    refreshList: function() {
      this.addedOrganisationKey = this.addedOrganisationKey + 1;
      this.keyCount = this.keyCount + 1;
      this.getOrganisations();
    },
    addBtn: function()
    {
      let self = this;
      this.loading = true;
      this.$http
        .post(this.user.hostname + "/administration/authentications/custom/add", this.AddCustomAuthMethodSettingDTO )
        .then((response) => {
          self.loading = false;
          self.items = response.data.items;
          self.$noty.info(self.$t("ADDED"));
        })
        .catch(() => {
          self.loading = false;
        });
    },
    removeBtn: function()
    {
      let self = this;
      this.$bvModal
        .msgBoxConfirm(this.$t("ARE_YOU_SURE"), {
          size: "sm",
          buttonSize: "sm",
          okVariant: "primary",
          okTitle: this.$t("YES"),
          cancelTitle: this.$t("NO"),
          bodyClass: "messagebox",
          footerClass: "p-2  text-center messagebox",
          hideHeaderClose: false,
          centered: true,
        })
        .then(function(value) {
          if (value) {
            self.loading = true;
            self.$http
              .post(self.user.hostname + "/administration/authentications/custom/remove", {
                remove_id: self.selected
              })
              .then((response) => {
                self.loading = false;
                self.items = response.data.items;
                self.$noty.info(self.$t("REMOVED"));
              })
              .catch(() => {
                self.loading = false;
              });
          }
        })
        .catch(function() {});
    },
    getMethods: function() {
      let self = this;
      this.loading = true;
      this.$http
        .get(this.user.hostname + "/administration/authentication/custom/list")
        .then((response) => {
          self.items = response.data.items;
          self.loading = false;
        })
        .catch(() => {
          self.loading = false;
        });
    },
    getProviders: function() {
      let self = this;
      this.loading = true;
      this.$http
        .get(this.user.hostname + "/administration/authentication/providers/custom/list")
        .then((response) => {
          self.custom = response.data;
          self.loading = false;
        })
        .catch(() => {
          self.loading = false;
        });
    },
    updateBtn: function() {
      let self = this;
      if (this != null) {
        for (let ix = 0; ix < this.items.length; ix++) {
          this.items[ix].login = parseInt(this.items[ix].login);
          this.items[ix].external = parseInt(this.items[ix].external);
          this.items[ix].loa_level = parseInt(this.items[ix].loa_level);
        }
        this.loading = true;
        this.$http
          .post(
            this.user.hostname + "/administration/authentications/custom/update",
            {
              items: this.items,
            }
          )
          .then((response) => {
            self.loading = false;
            self.items = response.data.items;
            self.$noty.info(self.$t("SAVED"));
          })
          .catch(() => {
            self.loading = false;
          });
      }
    }
  },
  computed: {
    providers() {
      let providerItems = this.custom.filter(function(item) {
        return item
      }).map(function (x) { return { text: x.name, value: x.name } ; });
      providerItems.unshift({ text: this.$t("NONE_SELECTED"), value: "" });
      return providerItems;
    },
  },
  mounted() {
    this.getMethods();
    this.getProviders();
  },
};
</script>
<style></style>
