var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "section",
    { staticClass: "mt-2" },
    [
      _c(
        "b-form",
        { staticClass: "mt-2", attrs: { inline: "" } },
        [
          _c("b-form-input", {
            staticClass: "ml-2",
            attrs: { trim: "", placeholder: _vm.$t("NAME") },
            on: {
              keydown: function ($event) {
                if (
                  !$event.type.indexOf("key") &&
                  _vm._k($event.keyCode, "enter", 13, $event.key, "Enter")
                )
                  return null
                $event.preventDefault()
              },
            },
            model: {
              value: _vm.AddCustomAuthMethodSettingDTO.name,
              callback: function ($$v) {
                _vm.$set(_vm.AddCustomAuthMethodSettingDTO, "name", $$v)
              },
              expression: "AddCustomAuthMethodSettingDTO.name",
            },
          }),
          _c("b-form-select", {
            staticClass: "ml-2",
            attrs: { placeholder: _vm.$t("SELECT"), options: _vm.providers },
            model: {
              value: _vm.AddCustomAuthMethodSettingDTO.mode,
              callback: function ($$v) {
                _vm.$set(_vm.AddCustomAuthMethodSettingDTO, "mode", $$v)
              },
              expression: "AddCustomAuthMethodSettingDTO.mode",
            },
          }),
          _c(
            "b-button",
            {
              staticClass: "ml-2",
              attrs: { disabled: !_vm.AddCustomAuthMethodSettingDTO.mode },
              on: { click: _vm.addBtn },
            },
            [_vm._v(_vm._s(_vm.$t("ADD")))]
          ),
        ],
        1
      ),
      _c("b-table", {
        staticClass: "mt-2",
        attrs: {
          bordered: "",
          small: "",
          fields: _vm.fields,
          items: _vm.items,
          busy: _vm.loading,
          "tbody-tr-attr": _vm.rowAttributes,
        },
        scopedSlots: _vm._u([
          {
            key: "cell(remove)",
            fn: function (data) {
              return [
                _c("b-form-checkbox", {
                  attrs: { value: data.item.id },
                  model: {
                    value: _vm.selected,
                    callback: function ($$v) {
                      _vm.selected = $$v
                    },
                    expression: "selected",
                  },
                }),
              ]
            },
          },
          {
            key: "cell(name)",
            fn: function (data) {
              return [
                _c("b-form-input", {
                  staticClass: "ml-2",
                  attrs: { trim: "", placeholder: _vm.$t("NAME") },
                  model: {
                    value: data.item.name,
                    callback: function ($$v) {
                      _vm.$set(data.item, "name", $$v)
                    },
                    expression: "data.item.name",
                  },
                }),
              ]
            },
          },
          {
            key: "cell(auth_method)",
            fn: function (data) {
              return [
                data.item.auth_method != ""
                  ? _c("span", [
                      _vm._v(
                        _vm._s(_vm.$t("AUTH_METHODS." + data.item.auth_method))
                      ),
                    ])
                  : _vm._e(),
              ]
            },
          },
          {
            key: "cell(loa_level)",
            fn: function (data) {
              return [
                _c("b-form-select", {
                  attrs: {
                    name: "custom_authentications_loa_level",
                    options: _vm.loa_levels,
                  },
                  model: {
                    value: data.item.loa_level,
                    callback: function ($$v) {
                      _vm.$set(data.item, "loa_level", $$v)
                    },
                    expression: "data.item.loa_level",
                  },
                }),
              ]
            },
          },
          {
            key: "cell(identifier)",
            fn: function (data) {
              return [
                _c("b-form-select", {
                  attrs: {
                    name: "custom_authentications_identifier",
                    options: _vm.identifiers,
                  },
                  model: {
                    value: data.item.identifier,
                    callback: function ($$v) {
                      _vm.$set(data.item, "identifier", $$v)
                    },
                    expression: "data.item.identifier",
                  },
                }),
              ]
            },
          },
          {
            key: "cell(login)",
            fn: function (data) {
              return [
                _c("b-form-checkbox", {
                  attrs: {
                    disabled:
                      _vm.user.information.authentication_type == "custom" &&
                      _vm.user.information.authentication_method ==
                        data.item.id + "",
                    value: "1",
                    "unchecked-value": "0",
                  },
                  model: {
                    value: data.item.login,
                    callback: function ($$v) {
                      _vm.$set(data.item, "login", $$v)
                    },
                    expression: "data.item.login",
                  },
                }),
              ]
            },
          },
          {
            key: "cell(mode)",
            fn: function (data) {
              return [
                _c("b-form-select", {
                  attrs: {
                    name: "custom_authentications_mode",
                    options: _vm.providers,
                  },
                  model: {
                    value: data.item.mode,
                    callback: function ($$v) {
                      _vm.$set(data.item, "mode", $$v)
                    },
                    expression: "data.item.mode",
                  },
                }),
              ]
            },
          },
        ]),
      }),
      _vm.items.length != 0
        ? _c(
            "div",
            [
              _c(
                "b-button",
                { attrs: { variant: "primary" }, on: { click: _vm.updateBtn } },
                [_vm._v(_vm._s(_vm.$t("SAVE")))]
              ),
              _c(
                "b-button",
                {
                  attrs: {
                    variant: "danger",
                    disabled: _vm.selected.length == 0,
                  },
                  on: { click: _vm.removeBtn },
                },
                [_vm._v(_vm._s(_vm.$t("REMOVE_SELECTED")))]
              ),
            ],
            1
          )
        : _vm._e(),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }